var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon,
      },
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.rop !== "new"
                          ? _c("asyent-form-info", {
                              attrs: { "no-title": "", "no-maximize": "" },
                              model: {
                                value: _vm.formData,
                                callback: function ($$v) {
                                  _vm.formData = $$v
                                },
                                expression: "formData",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              title: _vm.ae$lng("General"),
                              expandable: "",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Code"),
                                        name: "code",
                                        "view-mode": _vm.viewMode,
                                      },
                                      model: {
                                        value: _vm.formData["code"],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "code", $$v)
                                        },
                                        expression: "formData['code']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("Description"),
                                        name: "description",
                                        "view-mode": _vm.viewMode,
                                      },
                                      model: {
                                        value: _vm.formData["description"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "formData['description']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("asyent-toc-heading", {
                          attrs: {
                            caption: _vm.ae$lng("Access Roles"),
                            level: 1,
                          },
                        }),
                        _c("asyent-form-select", {
                          attrs: {
                            "form-errors": _vm.formErrors,
                            "form-rules": _vm.formFieldsRules,
                            "is-disabled-func": _vm.isDisabled,
                            "items-reload": _vm.rolesReload,
                            props: props,
                            "enable-select-all": "",
                            label: _vm.ae$lng("Access Roles"),
                            multiple: "",
                            name: "roles",
                            sortable: "",
                            title: _vm.ae$lng("Access Roles"),
                            "view-mode": _vm.viewMode,
                            "toc-title": "",
                          },
                          model: {
                            value: _vm.formData["roles"],
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "roles", $$v)
                            },
                            expression: "formData['roles']",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("asyent-toc"),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }